import { createStyle } from "@laba/react-common";
import { Theme } from "model/theme";

export const useSignatureInputStyles = createStyle(
  (_theme: Theme) => ({
    img: {
      height: "100%",
      width: "100%",
      objectFit: "contain"
    }
  }),
  "SignatureInput"
);
