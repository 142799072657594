import { FC, getClassName, ReactElement } from "@laba/react-common";
import React, { useState, useEffect, useMemo } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { MapComponent } from "components/maps/gmap/helpers/components/MapComponent/MapComponent";
import { Marker } from "components/maps/gmap/helpers/components/Marker/Marker";
import {
  ControlPermission,
  MarkerIcon,
  POIsCategory,
  GestureHandlingValue,
  MapObject,
  InfoWindowObject
} from "components/maps/gmap/helpers/types";
import {
  getDefaultsControls,
  getGmapPositionFromPosition
} from "components/maps/gmap/helpers/utils";
import { getInfoWindow } from "components/maps/gmap/helpers/getters";
import { useMapStyles } from "components/maps/gmap/useMapStyles";
import { Optional, Position, Noop, DefinedPosition } from "@laba/ts-common";

export interface MapMarker {
  position: DefinedPosition;
  icon?: string | MarkerIcon;
  onClick?: Noop;
  componentOnOpen?: ReactElement;
  isCurrentLocationMarker?: boolean;
}

export enum MapType {
  Dynamic = "dynamic",
  Static = "static",
  Disabled = "disabled"
}

export interface MapProps {
  apiKey: string;
  className?: string;
  center: DefinedPosition;
  zoom: number;
  controls?: ControlPermission;
  markers?: MapMarker[];
  onChangeCenterOrZoom?: (
    zoom?: number,
    currentLocation?: DefinedPosition
  ) => void;
  filterPOIs?: POIsCategory[];
  gestureHandling?: GestureHandlingValue;
  type?: MapType;
  SearchInThisArea?: ReactElement;
  currentPosition?: Position;
}

export const Map: FC<MapProps> = ({
  apiKey,
  className,
  zoom,
  center,
  onChangeCenterOrZoom,
  filterPOIs,
  SearchInThisArea,
  controls,
  currentPosition,
  markers = [],
  gestureHandling = GestureHandlingValue.Greedy,
  type = MapType.Dynamic
}) => {
  const classes = useMapStyles();
  const [map, setMap] = useState<MapObject>();
  const [infoWindow, setInfoWindow] = useState<InfoWindowObject>();
  const defaultControls = useMemo(() => getDefaultsControls(type), [type]);
  const controlsValues = useMemo(
    () => ({
      ...defaultControls,
      ...controls
    }),
    [defaultControls, controls]
  );
  const mapCenter = useMemo(() => {
    return getGmapPositionFromPosition({
      latitude: center.latitude,
      longitude: center.longitude
    });
  }, [center]);

  useEffect(() => {
    map && setInfoWindow(getInfoWindow());
  }, [setInfoWindow, map]);

  const getCurrentPositionMarker = (): Optional<MapMarker> => {
    if (
      !currentPosition ||
      !currentPosition.latitude ||
      !currentPosition.longitude
    ) {
      return undefined;
    }
    return {
      position: {
        longitude: currentPosition.longitude,
        latitude: currentPosition.latitude
      },
      isCurrentLocationMarker: true
    };
  };

  const mappedMarkers = [...markers, getCurrentPositionMarker()];

  return (
    <div className={getClassName(classes.root, className)}>
      <Wrapper apiKey={apiKey}>
        <MapComponent
          map={map}
          setMap={setMap}
          zoom={zoom}
          center={mapCenter}
          controls={controlsValues}
          onChangeCenterOrZoom={onChangeCenterOrZoom}
          filterPOIs={filterPOIs}
          gestureHandling={gestureHandling}
          type={type}
          infoWindow={infoWindow}
        >
          {mappedMarkers.map((m, i) =>
            m ? (
              <Marker
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                position={getGmapPositionFromPosition(m.position)}
                icon={m.icon}
                onClick={m.onClick}
                map={map}
                infoWindow={infoWindow}
                componentOnOpen={m.componentOnOpen}
                isCurrentLocationMarker={m.isCurrentLocationMarker}
              />
            ) : null
          )}
        </MapComponent>
      </Wrapper>
      <div className={classes.searchControl}>{SearchInThisArea}</div>
    </div>
  );
};
