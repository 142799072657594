import haversine from "haversine-distance";
import { Optional } from "model/types";

export interface Position {
  longitude?: number;
  latitude?: number;
}

export type DefinedPosition = Required<Position>;

export enum DistanceVariant {
  Meters = "Meters",
  Kilometers = "Kilometers"
}

export interface Distance {
  distance: number;
  distanceUnit: DistanceVariant;
}

export const validatePosition = (
  position: Position
): position is DefinedPosition => {
  return position.latitude !== undefined && position.longitude !== undefined;
};

export const getDistanceFromLatLongBetweenTwoPoints = (
  p1?: Position,
  p2?: Position
): Optional<Distance> => {
  if (!p1 || !p2) return undefined;

  if (p1.latitude === undefined || p1.longitude === undefined) return undefined;

  if (p2.latitude === undefined || p2.longitude === undefined) return undefined;

  const distance = haversine(
    { latitude: p1.latitude, longitude: p1.longitude },
    { latitude: p2.latitude, longitude: p2.longitude }
  );

  if (distance < 1000) {
    return {
      distance: Math.round(distance),
      distanceUnit: DistanceVariant.Meters
    };
  }

  if (distance < 10000) {
    return {
      distance: Number((distance / 1000).toFixed(1)),
      distanceUnit: DistanceVariant.Kilometers
    };
  }

  return {
    distance: Math.round(distance / 1000),
    distanceUnit: DistanceVariant.Kilometers
  };
};
