import {
  StyleVariant,
  createStyle,
  flexColumnMixin,
  paddingMixin,
  borderMixin,
  pxToRem,
  TextVariant,
  getClassName
} from "@laba/react-common";
import {
  FlexAlignSelf,
  flexItemMixin,
  FlexJustifyContent,
  marginMixin,
  TextAlign
} from "@laba/ts-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

interface SignaturePopupStylesProps {
  isMobile?: boolean;
}

interface SignaturePopupClassNames {
  signaturePad: string;
  cleanIcon: string;
  instructionsWrapper: string;
  instructionText: string;
  popup?: string;
}

const useSignaturePopupStaticStyle = createStyle(
  (theme: Theme) => ({
    popup: {
      ...flexColumnMixin({ justifyContent: FlexJustifyContent.SpaceBetween }),
      ...borderMixin({
        side: {
          all: pxToRem(1)
        },
        style: "dashed",
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.Main
        )
      })
    },
    mobilePopup: {
      ...marginMixin({ top: 0, leftRight: 32 }),
      height: "100%"
    },
    desktopPopup: {
      height: pxToRem(236)
    },
    signaturePad: {
      width: "100%",
      height: "100%",
      position: "absolute"
    },
    cleanIcon: {
      ...flexItemMixin({ align: FlexAlignSelf.FlexEnd }),
      ...marginMixin({ top: 20, right: 20 }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Main
      )
    },
    instructionsWrapper: {
      ...flexColumnMixin({ gap: 8 }),
      marginTop: "auto",
      userSelect: "none",
      textAlign: TextAlign.Center,
      width: "100%"
    },
    instructionsWrapperDesktopPadding: {
      ...paddingMixin({ leftRight: 20, bottom: 48 })
    },
    instructionsWrapperMobilePadding: {
      ...paddingMixin({ leftRight: 16, bottom: 156 })
    },
    instructionText: {
      ...themeTextMixin(theme, TextVariant.Body1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayLight
      )
    }
  }),
  "SignaturePopupStatic"
);

export const useSignaturePopupStyle = (
  props: SignaturePopupStylesProps
): SignaturePopupClassNames => {
  const { isMobile } = props;
  const {
    signaturePad,
    cleanIcon,
    instructionsWrapper,
    instructionText,
    popup,
    mobilePopup,
    desktopPopup,
    instructionsWrapperDesktopPadding,
    instructionsWrapperMobilePadding
  } = useSignaturePopupStaticStyle();

  return {
    signaturePad,
    cleanIcon,
    instructionsWrapper: getClassName(
      instructionsWrapper,
      isMobile
        ? instructionsWrapperMobilePadding
        : instructionsWrapperDesktopPadding
    ),
    instructionText,
    popup: getClassName(popup, isMobile ? mobilePopup : desktopPopup)
  };
};
