import {
  CodeSystemGroup,
  Concept,
  conceptHasSpecificProperty,
  KnownCodeSystemSystem,
  KnownConceptPropertyUse,
  KnownSpecialEffectPropertyCode,
  ModelId
} from "@laba/nexup-api";
import { Optional } from "@laba/ts-common";
import {
  useCodeSystemGroup,
  useFilterConceptsFromCodeSystemGroup
} from "components/hook/useCodeSystem";
import { useHasPermission } from "components/hook/UseHasPermission";
import { isEmpty } from "lodash-es";
import { AdminPanelSpecialPermissions } from "models/permissions/adminPanelPermissions";

const filterAdminRoleCallback = (c: Concept): boolean => {
  return !conceptHasSpecificProperty(
    KnownConceptPropertyUse.SpecialEffect,
    c,
    KnownSpecialEffectPropertyCode.AdminRole
  );
};

const filterAdminPanelCallback = (c: Concept): boolean => {
  return conceptHasSpecificProperty(
    KnownConceptPropertyUse.SpecialEffect,
    c,
    KnownSpecialEffectPropertyCode.AdminPanel
  );
};

export const useGetPractitionerRoleBasedOnPermissions = (
  organizationId?: ModelId
): Optional<CodeSystemGroup> => {
  const practitionerRole = useCodeSystemGroup(
    KnownCodeSystemSystem.PractitionerRole,
    organizationId
  );

  const userHasAdminPermission = useHasPermission();

  const userHasShowAllRoleCodesPermission = useHasPermission(
    AdminPanelSpecialPermissions.ShowAllRoleCodes
  );

  const filteredAdminRoles = useFilterConceptsFromCodeSystemGroup(
    filterAdminRoleCallback,
    practitionerRole
  );

  const filteredPractitionerRolesSystem = useFilterConceptsFromCodeSystemGroup(
    filterAdminPanelCallback,
    practitionerRole
  );

  if (userHasAdminPermission) return practitionerRole;

  if (userHasShowAllRoleCodesPermission) {
    return filteredAdminRoles;
  }

  if (isEmpty(filteredPractitionerRolesSystem?.concept))
    return practitionerRole;

  return filteredPractitionerRolesSystem;
};
