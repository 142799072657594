import {
  borderMixin,
  createStyle,
  FlexAlignItems,
  FlexAlignSelf,
  flexColumnMixin,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  marginMixin,
  paddingMixin,
  pxToRem
} from "@laba/react-common";
import { RadiusVariant, Theme, ThemeStyleColorVariant } from "model/theme";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

interface MuiButtonStylesProps {
  alignIcon: FlexAlignSelf;
  expanded?: boolean;
}

interface MuiButtonClassNames {
  root: string;
  label: string;
  endIcon: string;
}

export const useInputBaseStyles = createStyle(
  (_theme: Theme) => ({
    root: { ...flexColumnMixin({ gap: 4 }) }
  }),
  "InputBase"
);

const useMuiButtonStaticStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...paddingMixin({ topBottom: 8, leftRight: 12 }),
      ...borderMixin({
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        ),
        side: {
          all: 1
        },
        radius: { all: getThemeRadius(theme, RadiusVariant.Small) }
      })
    },
    label: {
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1),
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.SpaceBetween
      }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      height: "100%"
    },
    endIcon: {
      ...marginMixin({
        all: 0
      }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "MuiButtonStatic"
);

const useMuiButtonDynamicStyle = createStyle(
  (_theme: Theme) => ({
    root: (props: MuiButtonStylesProps) => ({
      height: props.expanded ? pxToRem(96) : pxToRem(40)
    }),
    endIcon: (props: MuiButtonStylesProps) => ({
      ...flexItemMixin({ align: props.alignIcon })
    })
  }),
  "MuiButtonDynamic"
);

export const useMuiButtonStyles = (
  props: MuiButtonStylesProps
): MuiButtonClassNames => {
  const { root, endIcon, label } = useMuiButtonStaticStyle();
  const { endIcon: endIconDynamic, root: rootDynamic } =
    useMuiButtonDynamicStyle(props);

  return {
    root: getClassName(root, rootDynamic),
    endIcon: getClassName(endIcon, endIconDynamic),
    label
  };
};
