import { FC, IconC, OnBlurEvent } from "@laba/react-common";
import React, { useState } from "react";
import { EditIcon, SignatureIcon } from "components/icons";
import { isEmpty } from "lodash-es";
import { FlexAlignSelf } from "@laba/ts-common";
import { SignaturePopup } from "components/popup/SignaturePopup/SignaturePopup";
import { AttachmentConfig } from "components/popup";
import { InputBase } from "../InputBase/InputBase";
import { useSignatureInputStyles } from "./useSignatureInputStyles";

export interface SignatureInputProps {
  inputText?: string;
  onSubmit: (file?: File) => void;
  disableInputButton?: boolean;
  EndIcon?: IconC;
  signature?: AttachmentConfig;

  popupPrimaryButtonText?: string;
  popupSecondaryButtonText?: string;
  popupHeaderTitle?: string;
  isMobile?: boolean;
  instructionText?: string;
  errorText?: string;
  onBlur?: OnBlurEvent;
  hideIcon?: boolean;
}

export const SignatureInput: FC<SignatureInputProps> = ({
  inputText,
  onSubmit,
  disableInputButton,
  instructionText,
  EndIcon = SignatureIcon,
  popupPrimaryButtonText,
  popupSecondaryButtonText,
  popupHeaderTitle,
  isMobile,
  errorText,
  signature,
  onBlur,
  hideIcon = false
}) => {
  const classes = useSignatureInputStyles();
  const [open, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onOpenPopup = () => {
    setIsOpen(true);
  };

  const onClosePopup = () => {
    onBlur?.();
    setIsOpen(false);
  };

  const onPrimaryButtonClickHandler = async (file?: File) => {
    setIsLoading(true);
    await onSubmit(file);
    onClosePopup();
    setIsLoading(false);
  };

  return (
    <>
      {isEmpty(signature?.url) ? (
        <InputBase
          text={inputText}
          onClickInput={onOpenPopup}
          EndIcon={hideIcon ? undefined : EndIcon}
          disabled={disableInputButton}
          errorText={errorText}
        />
      ) : (
        <InputBase
          disabled={disableInputButton}
          onClickInput={onOpenPopup}
          expanded
          EndIcon={hideIcon ? undefined : EditIcon}
          alignIcon={FlexAlignSelf.FlexStart}
          errorText={errorText}
        >
          <img
            src={signature?.url}
            alt={signature?.filename}
            className={classes.img}
          />
        </InputBase>
      )}
      <SignaturePopup
        isLoading={isLoading}
        open={open}
        headerTitle={popupHeaderTitle}
        primaryButtonText={popupPrimaryButtonText}
        secondaryButtonText={popupSecondaryButtonText}
        isMobile={isMobile}
        onPrimaryButtonClick={onPrimaryButtonClickHandler}
        onSecondaryButtonClick={onClosePopup}
        instructionText={instructionText}
      />
    </>
  );
};
