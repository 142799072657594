import { FC, SizeVariant } from "@laba/react-common";
import React, { useState } from "react";
import { Popup } from "components/popup/Popup/Popup";
import { Noop, ThemeStyleColorVariant, Nullable } from "@laba/ts-common";
import ReactSignatureCanvas from "react-signature-canvas";
import { TrashIcon } from "components/icons";
import { BaseIconButton } from "components/buttons";
import { isEmpty } from "lodash-es";
import { Divider, DividerSize } from "components/divider/Divider";
import { ImgMimes } from "components/download/DownloadLink/utils";
import { useSignaturePopupStyle } from "./useSignaturePopupStyles";

export interface SignaturePopupProps {
  open?: boolean;
  isMobile?: boolean;
  onPrimaryButtonClick?: (signatureFile?: File) => Promise<void> | void;
  disablePrimaryButton?: boolean;
  onSecondaryButtonClick?: Noop;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  headerTitle?: string;
  isLoading?: boolean;
  withCleanIcon?: boolean;
  withInstructions?: boolean;
  instructionText?: string;
}

export const SignaturePopup: FC<SignaturePopupProps> = ({
  open,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  headerTitle,
  primaryButtonText,
  secondaryButtonText,
  isMobile,
  isLoading,
  withCleanIcon = true,
  withInstructions = true,
  instructionText
}) => {
  const [sigCanvasRef, setSigCanvasRef] =
    useState<Nullable<ReactSignatureCanvas>>(null);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState<boolean>(true);
  const classes = useSignaturePopupStyle({ isMobile });

  const onClearSignatureCanvas = () => {
    setIsSignatureEmpty(true);
    sigCanvasRef?.clear();
  };

  const onPrimaryButtonClickHandler = async () => {
    if (sigCanvasRef?.isEmpty()) {
      onPrimaryButtonClick?.(undefined);
      onClearSignatureCanvas();
      return;
    }

    sigCanvasRef?.getTrimmedCanvas().toBlob(async blob => {
      if (!blob) {
        onPrimaryButtonClick?.(undefined);
        return;
      }

      const signatureFile = new File([blob], "signature.png", {
        type: ImgMimes.PNG
      });

      await onPrimaryButtonClick?.(signatureFile);
      onClearSignatureCanvas();
    }, ImgMimes.PNG);
  };

  const onSecondaryButtonClickHandler = async () => {
    onSecondaryButtonClick?.();
    onClearSignatureCanvas();
  };

  return (
    <Popup
      loading={isLoading}
      headerTitle={headerTitle}
      closableHeader
      open={open}
      onPrimaryButtonClick={onPrimaryButtonClickHandler}
      primaryButtonText={primaryButtonText}
      onSecondaryButtonClick={onSecondaryButtonClickHandler}
      secondaryButtonText={secondaryButtonText}
      onClose={onSecondaryButtonClick}
      disableBackdropClick
      isMobile={isMobile}
      popupSizeVariant={SizeVariant.Large}
      childrenClassName={classes.popup}
    >
      <ReactSignatureCanvas
        ref={setSigCanvasRef}
        canvasProps={{
          className: classes.signaturePad
        }}
        minWidth={1}
        maxWidth={1}
        onBegin={() => {
          setIsSignatureEmpty(false);
        }}
      />
      {withCleanIcon && !isSignatureEmpty && (
        <BaseIconButton
          className={classes.cleanIcon}
          Icon={TrashIcon}
          onClick={() => {
            setIsSignatureEmpty(true);
            sigCanvasRef?.clear();
          }}
          withoutPadding
        />
      )}
      {withInstructions && !isEmpty(instructionText) && (
        <div className={classes.instructionsWrapper}>
          <p className={classes.instructionText} title={instructionText}>
            {instructionText}
          </p>
          <Divider
            colorVariant={ThemeStyleColorVariant.Main}
            size={DividerSize.Small}
          />
        </div>
      )}
    </Popup>
  );
};
