import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { FC } from "@laba/react-common";

export const SignatureIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.95 19C4.28333 18.9167 3.04167 18.5667 2.225 17.95C1.40833 17.3333 1 16.4417 1 15.275C1 14.1917 1.44583 13.3125 2.3375 12.6375C3.22917 11.9625 4.46667 11.5583 6.05 11.425C6.7 11.375 7.1875 11.2708 7.5125 11.1125C7.8375 10.9542 8 10.7333 8 10.45C8 10.0167 7.75417 9.69167 7.2625 9.475C6.77083 9.25833 5.95833 9.1 4.825 9L5 7C6.71667 7.13333 7.97917 7.47917 8.7875 8.0375C9.59583 8.59583 10 9.4 10 10.45C10 11.3333 9.67917 12.025 9.0375 12.525C8.39583 13.025 7.45 13.325 6.2 13.425C5.13333 13.5083 4.33333 13.7042 3.8 14.0125C3.26667 14.3208 3 14.7417 3 15.275C3 15.8583 3.23333 16.2792 3.7 16.5375C4.16667 16.7958 4.95 16.95 6.05 17L5.95 19Z"
        fill="#4F4F4F"
      />
      <path
        d="M11.1247 20.9998H8V17.8751L17.2158 8.65928L20.3415 11.784L11.1247 20.9998ZM21.2321 10.8924L18.1083 7.7677L19.6327 6.24329C19.7099 6.16616 19.8014 6.10497 19.9022 6.06323C20.003 6.02149 20.111 6 20.2201 6C20.3292 6 20.4372 6.02149 20.538 6.06323C20.6387 6.10497 20.7303 6.16616 20.8074 6.24329L22.7575 8.19333C22.9128 8.34935 23 8.56054 23 8.78069C23 9.00084 22.9128 9.21202 22.7575 9.36804L21.2331 10.8915L21.2321 10.8924Z"
        fill="#4F4F4F"
      />
    </SvgIcon>
  );
};
