import { FC, IconC, OnBlurEvent } from "@laba/react-common";
import React, { useState, useCallback } from "react";
import { UploadArrow } from "components/icons";
import { differenceBy, isEmpty } from "lodash-es";
import {
  AttachmentPopup,
  PopupState
} from "components/popup/AttachmentPopup/AttachmentPopup";
import {
  AttachmentConfig,
  AttachmentLoadingState,
  mapLoadingFilesToAttachmentConfigOption
} from "components/popup/AttachmentPopup/helpers";
import { BaseAttachmentProps } from "components/popup/AttachmentPopup/AttachmentCardContent/AttachmentCardContent";
import { Noop } from "@laba/ts-common";
import { InputBase } from "../InputBase/InputBase";

export interface AttachmentInputProps extends BaseAttachmentProps {
  onSubmit: (values: AttachmentConfig[]) => void;
  disableInputButton?: boolean;
  EndIcon?: IconC;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  headerTitle?: string;
  placeholder?: string;
  onClosePopup?: Noop;
  onBlur?: OnBlurEvent;
}

export const AttachmentInput: FC<AttachmentInputProps> = ({
  onSubmit,
  headerTitle,
  primaryButtonText,
  secondaryButtonText,
  uploadFiles,
  dropButtonText,
  dropPromptText,
  orText,
  dropRejectedText,
  acceptedFileExtensions,
  acceptedFileExtensionsText,
  placeholder,
  fileValues,
  disableInputButton,
  onDropError,
  errorMessageMapper,
  maxFiles,
  maxFileSize,
  retryDownloadText,
  EndIcon = UploadArrow,
  downloadFilesButtonText,
  downloadFilesText,
  showDownloadFiles,
  onClosePopup,
  fileErrors,
  isMobile,
  onBlur
}) => {
  const [popupFiles, setPopupFiles] = useState<PopupState>({
    toSubmitFiles: [],
    loadingFiles: [],
    failedFiles: []
  });
  const [open, setIsOpen] = useState(false);

  const deleteToSubmitFile = (fileToDelete: AttachmentConfig) => {
    setPopupFiles(prevState => {
      return {
        loadingFiles: prevState.loadingFiles,
        toSubmitFiles: prevState.toSubmitFiles.filter(
          f => f.url !== fileToDelete.url
        ),
        failedFiles: prevState.failedFiles.filter(
          f => f.name !== fileToDelete.filename
        )
      };
    });
  };

  const innerOnClosePopup = () => {
    setPopupFiles({
      toSubmitFiles: [],
      loadingFiles: [],
      failedFiles: []
    });
    setIsOpen(false);
    onBlur?.();
    onClosePopup?.();
  };

  const onPrimaryButtonClickHandler = () => {
    onSubmit(popupFiles.toSubmitFiles);
    innerOnClosePopup();
  };

  const onSecondaryButtonClickHandler = () => {
    innerOnClosePopup();
  };

  const updateToSubmitFiles = (
    files: AttachmentConfig[],
    filesToUpload: File[]
  ) => {
    setPopupFiles(prevState => {
      if (isEmpty(prevState.loadingFiles)) return prevState;
      const failedFiles = filesToUpload.filter(
        f =>
          isEmpty(files) ||
          !files.some(attachmentConfig => f.name === attachmentConfig.filename)
      );
      return {
        loadingFiles: [],
        toSubmitFiles: [...prevState.toSubmitFiles, ...files],
        failedFiles: [...prevState.failedFiles, ...failedFiles]
      };
    });
  };

  const updateLoadingFiles = (
    files: AttachmentConfig[],
    filesToUpload: File[]
  ) => {
    setPopupFiles(prevState => {
      return {
        toSubmitFiles: prevState.toSubmitFiles,
        loadingFiles: [...prevState.loadingFiles, ...files],
        failedFiles: differenceBy(
          prevState.failedFiles,
          filesToUpload,
          f => f.name
        )
      };
    });
  };

  const uploadFilesWrapper = async (files: File[]) => {
    const mappedLoadingFiles = mapLoadingFilesToAttachmentConfigOption(files);
    updateLoadingFiles(mappedLoadingFiles, files);
    const uploadedFiles = (await uploadFiles?.(files)) ?? [];
    updateToSubmitFiles(uploadedFiles, files);
    return uploadedFiles;
  };

  const onClickInput = () => {
    setIsOpen(true);
  };

  const setToSubmitFiles = useCallback(
    (files: AttachmentConfig[]) => {
      setPopupFiles(prevState => {
        return {
          loadingFiles: prevState.loadingFiles,
          toSubmitFiles: [...files],
          failedFiles: prevState.failedFiles
        };
      });
    },
    [setPopupFiles]
  );

  const toSubmitFiles = [
    ...popupFiles.toSubmitFiles,
    ...popupFiles.loadingFiles,
    ...mapLoadingFilesToAttachmentConfigOption(
      popupFiles.failedFiles,
      AttachmentLoadingState.FAILED
    )
  ];

  return (
    <>
      <InputBase
        onClickInput={onClickInput}
        EndIcon={EndIcon}
        text={placeholder}
        disabled={disableInputButton}
      />
      <AttachmentPopup
        popupFiles={popupFiles}
        headerTitle={headerTitle}
        open={open}
        toSubmitFiles={toSubmitFiles}
        onPrimaryButtonClick={onPrimaryButtonClickHandler}
        primaryButtonText={primaryButtonText}
        onSecondaryButtonClick={onSecondaryButtonClickHandler}
        secondaryButtonText={secondaryButtonText}
        isMobile={isMobile}
        uploadFiles={uploadFilesWrapper}
        setToSubmitFiles={setToSubmitFiles}
        fileValues={fileValues}
        deleteToSubmitFile={deleteToSubmitFile}
        dropButtonText={dropButtonText}
        dropPromptText={dropPromptText}
        orText={orText}
        dropRejectedText={dropRejectedText}
        acceptedFileExtensions={acceptedFileExtensions}
        acceptedFileExtensionsText={acceptedFileExtensionsText}
        onDropError={onDropError}
        errorMessageMapper={errorMessageMapper}
        maxFiles={maxFiles}
        maxFileSize={maxFileSize}
        retryDownloadText={retryDownloadText}
        downloadFilesButtonText={downloadFilesButtonText}
        downloadFilesText={downloadFilesText}
        showDownloadFiles={showDownloadFiles}
        fileErrors={fileErrors}
      />
    </>
  );
};
