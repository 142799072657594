import React, { useMemo } from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  ModelId,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  PractitionerKey,
  PractitionerRole,
  PractitionerRoleKey,
  PractitionerRoleListQueryParamsKey,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { ReferenceManyResourceInput } from "components/generic/ReferenceManyResourceInput/ReferenceManyResourceInput";
import { getDefaultResourceTransform } from "components/generic/ResourceForm/ResourceForm";
import { DeepPartial } from "@laba/ts-common";
import { TextField } from "components/generic/TextField/TextField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { isEmpty } from "lodash-es";
import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";
import { useGetPractitionerRoleBasedOnPermissions } from "components/hook/useGetPractitionerRoleBasedOnPermissions";
import { useGetFirstPractitionerOrganizationId } from "./hooks/useGetFirstPractitionerOrganizationId";

export const practitionerRoleTransform =
  getDefaultResourceTransform<PractitionerRole>(ResourceType.PractitionerRole);
export const practitionerRoleJsonToModel: JsonToModel<PractitionerRole> =
  getDefaultJsonToModel(draft => {
    draft.practitioner = undefined;
    draft.organization = undefined;
  });

export const tk = tkCP.adminPage[ResourceType.Practitioner];
export const PractitionerRoleTabContent: FC = () => {
  const { t } = useI18n();
  const firstPractitionerOrganizationId =
    useGetFirstPractitionerOrganizationId();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const practitionerId = useWatchResourceForm<ModelId>(PractitionerKey.id);
  const organizationId =
    firstPractitionerOrganizationId ?? workspaceOrganizationId;
  const practitionerRole =
    useGetPractitionerRoleBasedOnPermissions(organizationId);
  const defaultValue = useMemo<DeepPartial<PractitionerRole>>(
    () => ({
      active: true,
      practitioner: practitionerId,
      organization: firstPractitionerOrganizationId,
      resourceType: ResourceType.PractitionerRole
    }),
    [practitionerId, firstPractitionerOrganizationId]
  );
  return (
    <ReferenceManyResourceInput<PractitionerRole>
      resourceType={ResourceType.PractitionerRole}
      resourceSource={PractitionerRoleListQueryParamsKey.practitioner}
      label={t(tk.fields.role)}
      resourceTransformer={practitionerRoleTransform}
      defaultCreateValue={defaultValue}
      createEditPopupTitle={t(tk.fields.roleCreateEditPopUpTitle)}
      canCreate={!isEmpty(practitionerId)}
      listFieldList={[
        <TextField
          key={PractitionerRoleKey.id}
          source={PractitionerRoleKey.id}
          label={t(tk.fields.roleId)}
        />,
        <TextField
          key={PractitionerRoleKey.role}
          source={PractitionerRoleKey.role}
          label={t(tk.fields.roleCode)}
        />,
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.organization)}
          source={PractitionerRoleKey.organization}
          optionTextKey={OrganizationKey.name}
        />,
        <TextField
          key={PractitionerRoleKey.active}
          source={PractitionerRoleKey.active}
          label={t(tk.fields.roleActive)}
        />
      ]}
      editInputList={[
        <TextInput
          disabled
          source={PractitionerRoleKey.id}
          label={t(tk.fields.roleId)}
        />,
        <CodeSystemSelectorInput
          source={PractitionerRoleKey.role}
          label={t(tk.fields.roleCode)}
          codeSystem={practitionerRole}
          organizationId={organizationId}
        />,
        <OrganizationReferenceInput
          source={PractitionerRoleKey.organization}
          label={t(tk.fields.roleOrganization)}
          type={OrganizationCombinedType.ProviderAndGroup}
          extraFilters={{
            [OrganizationListQueryParamsKey.active]: true
          }}
          required
          disabled={workspaceOrganizationId !== undefined}
        />,
        <BooleanInput
          source={PractitionerRoleKey.active}
          label={t(tk.fields.roleActive)}
        />
      ]}
    />
  );
};
