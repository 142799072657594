import { useState, useCallback, useMemo, useEffect } from "react";
import {
  defaultLocationOptions,
  errorMapper,
  ErrorPosition,
  onGetCurrentPosition
} from "hooks/location/helpers";
import { DefinedPosition, Position } from "@laba/ts-common";

// you should not call useCurrentUserPosition and useUserPosition on the same
// rendered component or child rendered components at the same time
// https://bugs.chromium.org/p/chromium/issues/detail?id=586015

export interface DevicePositionResultData {
  currentPosition?: Position;
  error?: ErrorPosition;
}

export const useIntervalDevicePosition = (
  updateTimerMs: number,
  options?: PositionOptions,
  triggerUpdate?: boolean,
  afterUpdatePositionData?: (currentPosition?: DefinedPosition) => void,
  disable = false
): DevicePositionResultData => {
  const [currentPosition, setCurrentPosition] = useState<DefinedPosition>();
  const [error, setError] = useState<ErrorPosition>();

  const positionOptions = useMemo(
    () => ({
      ...defaultLocationOptions,
      ...options
    }),
    [options]
  );

  const locationCallback = useCallback(() => {
    onGetCurrentPosition(
      position => {
        const pos: DefinedPosition = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        };
        setCurrentPosition(pos);
        afterUpdatePositionData?.(pos);
      },
      positionError => {
        const err = errorMapper(positionError);
        setCurrentPosition(undefined);
        setError(err);
      },
      positionOptions
    );
  }, [positionOptions, afterUpdatePositionData]);

  useEffect(() => {
    if (disable) return;
    locationCallback();
    const idInterval = setInterval(locationCallback, updateTimerMs);
    return () => clearInterval(idInterval);
  }, [locationCallback, updateTimerMs, triggerUpdate, disable]);

  return { currentPosition, error };
};
