import React, { useMemo, useState } from "react";
import {
  FC,
  getClassName,
  TypeVariant,
  useIntervalDevicePosition,
  withMemo,
  defaultGeolocationUpdateTimerMs
} from "@laba/react-common";
import { DefinedPosition, Position, validatePosition } from "@laba/ts-common";
import { Map, MapType } from "components/maps/gmap/Map";
import { ErrorText } from "components/text";
import { DefaultButton } from "components/buttons";
import { isEqual } from "lodash-es";
import { usePositionTrackerStyle } from "./usePositionTrackerStyles";
import { defaultStaticMapZoom } from "../gmap/helpers/utils";

export enum PositionTrackerMode {
  Static = "Static",
  Dynamic = "Dynamic"
}

export interface PositionTrackerProps {
  className?: string;
  instructionText?: string;
  buttonText?: string;
  geolocationUpdateTimerMs?: number;
  gmapAPIKEY: string;
  mapZoom?: number;
  errorText?: string;
  afterUpdatePositionData?: (currentPosition?: DefinedPosition) => void;
  isMobile?: boolean;
  positionValue?: Position;
  mode?: PositionTrackerMode;
}

export const PositionTrackerInt: FC<PositionTrackerProps> = ({
  className,
  instructionText,
  buttonText,
  geolocationUpdateTimerMs = defaultGeolocationUpdateTimerMs,
  gmapAPIKEY,
  mapZoom = defaultStaticMapZoom,
  errorText,
  afterUpdatePositionData,
  isMobile,
  positionValue,
  mode = PositionTrackerMode.Dynamic
}) => {
  const classes = usePositionTrackerStyle();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const isModeStatic = useMemo(
    () => isEqual(mode, PositionTrackerMode.Static),
    [mode]
  );

  const { currentPosition: devicePosition } = useIntervalDevicePosition(
    geolocationUpdateTimerMs,
    undefined,
    triggerUpdate,
    afterUpdatePositionData,
    isModeStatic
  );

  const handleOnButtonClick = () => {
    setTriggerUpdate(prev => !prev);
  };

  const positionToRender = useMemo(() => {
    if (positionValue && validatePosition(positionValue)) {
      return positionValue;
    }
    if (devicePosition && validatePosition(devicePosition)) {
      return devicePosition;
    }
    return undefined;
  }, [positionValue, devicePosition]);

  if (positionToRender) {
    return (
      <Map
        className={classes.map}
        apiKey={gmapAPIKEY}
        zoom={mapZoom}
        center={positionToRender}
        currentPosition={positionToRender}
        type={MapType.Static}
      />
    );
  }

  return (
    <div className={getClassName(classes.root, className)}>
      <ErrorText error={errorText} withLeftMargin={false} />
      <p className={classes.text} title={instructionText}>
        {instructionText}
      </p>
      <DefaultButton
        type={TypeVariant.Outlined}
        text={buttonText}
        title={buttonText}
        onClick={handleOnButtonClick}
        fullWidth={isMobile}
      />
    </div>
  );
};

export const PositionTracker = withMemo(PositionTrackerInt);
