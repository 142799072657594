import { FCC, getClassName, IconC } from "@laba/react-common";
import React from "react";
import MuiButton from "@material-ui/core/Button";
import { FlexAlignSelf, Noop } from "@laba/ts-common";
import { ErrorText } from "components/text";
import { useInputBaseStyles, useMuiButtonStyles } from "./useInputBaseStyles";

export interface InputBaseProps {
  className?: string;
  expanded?: boolean;
  onClickInput: Noop;
  EndIcon?: IconC;
  disabled?: boolean;
  text?: string;
  fullWidth?: boolean;
  alignIcon?: FlexAlignSelf;
  errorText?: string;
}

export const InputBase: FCC<InputBaseProps> = ({
  onClickInput,
  EndIcon,
  disabled,
  text,
  fullWidth = true,
  children,
  className,
  expanded = false,
  alignIcon = FlexAlignSelf.Center,
  errorText
}) => {
  const muiButtonClasses = useMuiButtonStyles({ alignIcon, expanded });
  const classes = useInputBaseStyles();
  return (
    <div className={getClassName(classes.root, className)}>
      <MuiButton
        classes={muiButtonClasses}
        onClick={onClickInput}
        endIcon={EndIcon && <EndIcon />}
        variant="outlined"
        disabled={disabled}
        fullWidth={fullWidth}
      >
        {children ?? <p title={text}>{text}</p>}
      </MuiButton>
      <ErrorText error={errorText} />
    </div>
  );
};
