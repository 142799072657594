import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  pxToRem,
  StyleVariant,
  TextVariant,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { Theme } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

interface PositionTrackerClassNames {
  root: string;
  text: string;
  map: string;
}

const usePositionTrackerStaticStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({
        gap: 8,
        alignItems: FlexAlignItems.FlexStart
      })
    },
    text: {
      ...themeTextMixin(theme, TextVariant.Body2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    map: { height: pxToRem(128) }
  }),
  "PositionTrackerStatic"
);

export const usePositionTrackerStyle = (): PositionTrackerClassNames => {
  const { root, text, map } = usePositionTrackerStaticStyle();

  return {
    root,
    text,
    map
  };
};
