import {
  ControlPermission,
  MapPosition,
  MapPositionObject,
  MapStylesListObject,
  POIsCategory,
  StaticMapConfig,
  GestureHandlingValue
} from "components/maps/gmap/helpers/types";
import { Optional, DefinedPosition } from "@laba/ts-common";
import { MapType } from "../Map";

export const defaultStaticMapZoom = 15;

export const getGmapPositionFromPosition = (
  position: DefinedPosition
): MapPosition => {
  return {
    lng: position.longitude,
    lat: position.latitude
  };
};

export const getPositionFromLatLngPosition = (
  pos?: MapPositionObject
): Optional<DefinedPosition> => {
  const currentLng = pos?.lng();
  const currentLat = pos?.lat();
  return currentLat !== undefined && currentLng !== undefined
    ? {
        longitude: currentLng,
        latitude: currentLat
      }
    : undefined;
};

export const getMapCustomStyles = (
  filterPOIs?: POIsCategory[]
): MapStylesListObject | null => {
  return (
    filterPOIs?.map(categoryToFilter => {
      return {
        featureType: categoryToFilter,
        stylers: [{ visibility: "off" }]
      };
    }) ?? null
  );
};

export const getStaticMapConfig = (
  isStatic: boolean,
  gestureHandling: GestureHandlingValue
): StaticMapConfig => {
  if (isStatic) {
    return {
      gestureHandling: GestureHandlingValue.None,
      clickableIcons: false,
      keyboardShortcuts: false
    };
  }
  return {
    gestureHandling,
    clickableIcons: true
  };
};

export const getDefaultsControls = (type: MapType): ControlPermission => ({
  zoomControl: type === MapType.Dynamic,
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  rotateControl: false,
  scaleControl: false
});
