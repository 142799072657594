import {
  Code,
  CodeSystemGroup,
  CodeSystemsRecord,
  CodeSystemSystem,
  CodeSystemWithStatus,
  Concept,
  ConceptProperty,
  ConceptPropertyUse,
  ModelReference,
  Organization
} from "@laba/nexup-api";
import { useMemo } from "react";
import { isEmpty } from "lodash-es";
import { useSelector } from "react-redux";
import {
  codeSystemRecordSelector,
  shouldDownloadSystemSelector
} from "store/workspace/selectors";
import { useAppDispatch } from "store/store";
import { downloadOrganizationCodeSystemGroup } from "store/workspace/events";
import { Optional } from "@laba/ts-common";
import { useEffectSelective } from "@laba/react-common";

const useCodeSystemGroupRecord = (
  codeSystemList: CodeSystemSystem[],
  organization?: ModelReference<Organization>
): CodeSystemsRecord => {
  const dispatch = useAppDispatch();
  const shouldDownloadSystem = useSelector(shouldDownloadSystemSelector);
  const codeSystemRecord = useSelector(codeSystemRecordSelector);
  const neededCodeSystems: CodeSystemSystem[] = useMemo(
    () =>
      codeSystemList.filter(system =>
        shouldDownloadSystem(system, organization)
      ),
    [codeSystemList, shouldDownloadSystem, organization]
  );
  useEffectSelective(
    () => {
      if (!isEmpty(neededCodeSystems)) {
        dispatch(
          downloadOrganizationCodeSystemGroup(neededCodeSystems, organization)
        );
      }
    },
    [dispatch, organization],
    [neededCodeSystems]
  );
  return Object.fromEntries(
    Object.entries(codeSystemRecord(organization)).filter(value =>
      codeSystemList.includes(value[0])
    )
  );
};

const useCodeSystemWithStatus = (
  codeSystem: CodeSystemSystem,
  organization?: ModelReference<Organization>
): Optional<CodeSystemWithStatus> => {
  const codeSystemList = useMemo(() => [codeSystem], [codeSystem]);
  const codeSystemRecord = useCodeSystemGroupRecord(
    codeSystemList,
    organization
  );
  return codeSystemRecord[codeSystem];
};

export const useCodeSystemGroup = (
  codeSystem: CodeSystemSystem,
  organization: Optional<ModelReference<Organization>>
): Optional<CodeSystemGroup> => {
  const codeSystemWithStatus = useCodeSystemWithStatus(
    codeSystem,
    organization
  );
  if (codeSystemWithStatus?.system)
    return codeSystemWithStatus.system as CodeSystemGroup;
};

// TODO HIS-15899 Unificar todos estos hooks con los de his
export const useCodeSystemGroupConceptFromCode = (
  system: CodeSystemSystem,
  code?: Code,
  organization?: ModelReference<Organization>
): Optional<Concept> => {
  const conceptList = useCodeSystemGroup(system, organization)?.concept;
  return conceptList?.find(concept => concept.code === code);
};

export const useCodeSystemGroupConceptFirstPropertyFromCode = (
  system: CodeSystemSystem,
  code?: Code,
  propertyUse?: ConceptPropertyUse,
  organization?: ModelReference<Organization>
): Optional<ConceptProperty> => {
  const concept = useCodeSystemGroupConceptFromCode(system, code, organization);
  return concept?.property.find(p => p.use === propertyUse);
};

export const useFilterConceptsFromCodeSystemGroup = (
  filterFn: (c: Concept) => boolean,
  codeSystemGroup?: CodeSystemGroup
): Optional<CodeSystemGroup> => {
  return useMemo(() => {
    return codeSystemGroup
      ? {
          system: codeSystemGroup.system,
          concept: codeSystemGroup.concept?.filter(filterFn)
        }
      : undefined;
  }, [codeSystemGroup, filterFn]);
};
